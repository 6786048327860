import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EDUCATION, FAMILY_STATUS, ORIENTATION, ROLE, SEX } from '../types/enum'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import $api from '../http'
import { EditUserProfileDTO, PROFILE_STEP } from '../types/server-communication/user'
import { GeoCircleDTO, GeoPolygonDTO, InterestZoneDTO } from '../utils/geo.dto'
import { type Interests, type Professions, type Skills } from '../utils/localization/i18n'
import {
  attemptFacebook,
  attemptForgotPasswordClaimCode,
  attemptGoogle,
  attemptLogin,
  attemptSetPassword,
  forgotPasswordSendCode,
  refreshAuthTokens,
  registrationConfirmAccount,
  registrationSetAddress,
} from './auth'

export interface UserInterface {
  id: string
  email: string
  phone?: string
  role: ROLE | null
  avatarFileName?: null | string
  fullName: string
  aboutMe?: string
  dateBirth?: string // interface of dayjs().toISOString(). in form it has dayjs.Dayjs interface
  cityBirth?: string
  studySchool?: string
  education?: EDUCATION
  sex?: SEX
  orientation?: ORIENTATION
  familyStatus?: FAMILY_STATUS
  certificates?: string[] //array of filenames saved in back-end upload folder
  nationality: string
  stepsToComplete: PROFILE_STEP[]
  professions: (Professions | string)[]
  skills: (Skills | string)[]
  interests: (Interests | string)[]
  address?: AddressDTO
  rate: number
  reviews?: any[]
  interestZone?: InterestZoneDTO
  createdAt: string
}

export type State = Partial<UserInterface> & {}

export const initialState: State = {}

export const attemptUpdateUserProfile = createAsyncThunk<
  EditUserProfileDTO,
  EditUserProfileDTO
>('profile/update', async (payload) => {
  await $api.post(`user`, payload) //TODO [Vlad]: Interfaces does not match
  return payload
})

// export const attemptUpdateUserAddress = createAsyncThunk<
//   Pick<UserInterface, 'address'>,
//   Pick<UserInterface, 'address'>
// >('profile/update-address', async (payload) => {
//   //TODO: Finish call to endpoint
//   console.log('!!!payload:', payload)
//   return payload
// })

export const attemptUpdateAvatar = createAsyncThunk<
  Pick<UserInterface, 'avatarFileName'>,
  Pick<UserInterface, 'avatarFileName'>
>('profile/update-avatar', async (payload) => {
  await await $api.post(`user/avatar`, payload)
  return payload
})

export const attemptUpdatePrivacy = createAsyncThunk<
  any, // Pick<UserInterface, 'privacy'>,
  any // Pick<UserInterface, 'privacy'>
>('profile/update-privacy', async (payload) => {
  console.log('attemptUpdatePrivacy payload:', payload)
  await await $api.post(`user/privacy`, payload)
  return payload
})

export const attemptUpdateAboutMe = createAsyncThunk<
  Pick<UserInterface, 'aboutMe'>,
  Pick<UserInterface, 'aboutMe'>
>('profile/update-aboutMe', async (payload) => {
  await $api.post(`user/about-me`, payload)
  return payload
})

export const attemptUpdateNationality = createAsyncThunk<
  Pick<UserInterface, 'nationality'>,
  Pick<UserInterface, 'nationality'>
>('profile/update-nationality', async (payload) => {
  await $api.post(`user/nationality`, payload)
  return payload
})

export const attemptUpdateSexIdentity = createAsyncThunk<
  Pick<UserInterface, 'sex' | 'orientation'>,
  Pick<UserInterface, 'sex' | 'orientation'>
>('profile/update-sex-identity', async (payload) => {
  await $api.post(`user/sex-identity`, payload)
  return payload
})

export const attemptUpdateEducation = createAsyncThunk<
  Pick<UserInterface, 'education' | 'studySchool'>,
  Pick<UserInterface, 'education' | 'studySchool'>
>('profile/update-education', async (payload) => {
  console.log('payload:', payload)
  await $api.post(`user/education`, payload)
  return payload
})

export const attemptUpdateFamilyStatus = createAsyncThunk<
  Pick<UserInterface, 'familyStatus'>,
  Pick<UserInterface, 'familyStatus'>
>('profile/update-family-status', async (payload) => {
  await $api.post(`user/family-status`, payload)
  return payload
})

export const attemptUpdateBirth = createAsyncThunk<
  Pick<UserInterface, 'cityBirth' | 'dateBirth'>,
  Pick<UserInterface, 'cityBirth' | 'dateBirth'>
>('profile/update-birth', async (payload) => {
  await $api.post(`user/birth`, payload)
  return payload
})

export const attemptUpdateProfessions = createAsyncThunk<
  Pick<UserInterface, 'professions'>,
  Pick<UserInterface, 'professions'>
>('profile/update-professions', async (payload) => {
  await $api.post(`user/professions`, payload)
  return payload
})

export const attemptUpdateSkills = createAsyncThunk<
  Pick<UserInterface, 'skills'>,
  Pick<UserInterface, 'skills'>
>('profile/update-skills', async (payload) => {
  await $api.post(`user/skills`, payload)
  return payload
})

export const attemptUpdateInterests = createAsyncThunk<
  Pick<UserInterface, 'interests'>,
  Pick<UserInterface, 'interests'>
>('profile/update-interests', async (payload) => {
  await $api.post(`user/interests`, payload)
  return payload
})

export const attemptUpdateCertificates = createAsyncThunk<
  Pick<UserInterface, 'certificates'>,
  Pick<UserInterface, 'certificates'>
>('profile/update-certificates', async (payload) => {
  await $api.post(`user/certificates`, payload)
  return payload
})

export const attemptUpdateInterestZone = createAsyncThunk<
  InterestZoneDTO,
  InterestZoneDTO
>('profile/update-interest-zone', async (payload) => {
  if (payload.polygon) {
    // invert [lat, lon] to [lon, lat]
    payload.polygon.coordinates = payload.polygon.coordinates.map((p) => {
      return p.map((point) => [point[1], point[0]])
    })
  }
  if (payload.circle) {
    payload.circle.center.coordinates = [
      payload.circle.center.coordinates[1],
      payload.circle.center.coordinates[0],
    ]
  }
  await $api.post(`user/interest-zone`, payload)
  return payload
})

interface UpdateProfileSecurityDTO {
  email?: string
  phone?: string
  address?: AddressDTO
  oldPassword?: string
  newPassword?: string
}

export const attemptUpdateSecurity = createAsyncThunk<void, UpdateProfileSecurityDTO>(
  'profile/update-security',
  async (payload) => {
    await $api.post(`user/security`, payload)
  },
)

export interface UserIdentityInterface {}

function convertUserFromBackToFront(user: State) {
  if (user.address?.coordinates 
    && user.address?.coordinates.type === 'Point'
  ) {
    user.address.coordinates.coordinates = [
      user.address.coordinates.coordinates[1],
      user.address.coordinates.coordinates[0],
    ]
  }

  if (user.interestZone?.polygon) {
    user.interestZone.polygon.coordinates = user.interestZone.polygon.coordinates.map(
      (p: [number, number][]) => p.map((points) => [points[1], points[0]]),
    )
  }

  if (user.interestZone?.circle) {
    user.interestZone.circle.center.coordinates = [
      user.interestZone.circle.center.coordinates[1],
      user.interestZone.circle.center.coordinates[0],
    ]
  }

  return user
}

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateAvatar: (
      state,
      { payload }: PayloadAction<UserInterface['avatarFileName']>,
    ) => {
      state.avatarFileName = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptUpdateUserProfile.fulfilled, (state, { payload }) => {
        return { ...state, ...payload } //keep existing keys
      })
      // .addCase(attemptUpdateUserAddress.fulfilled, (state, { payload }) => {
      //   state.address = payload.address
      // })
      .addCase(attemptUpdateAvatar.fulfilled, (state, { payload }) => {
        state.avatarFileName = payload.avatarFileName
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.AVATAR,
        )
      })
      .addCase(attemptUpdatePrivacy.fulfilled, (state, { payload }) => {
        // state.privacy = payload.privacy
        console.log('TODO: add logic to manage privacy setting.')
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.PRIVACY,
        )
      })
      .addCase(attemptUpdateAboutMe.fulfilled, (state, { payload }) => {
        state.aboutMe = payload.aboutMe
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.ABOUT_ME,
        )
      })
      .addCase(attemptUpdateNationality.fulfilled, (state, { payload }) => {
        state.nationality = payload.nationality
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.NATIONALITY,
        )
      })
      .addCase(attemptUpdateSexIdentity.fulfilled, (state, { payload }) => {
        state.sex = payload.sex
        state.orientation = payload.orientation
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.SEX,
        )
      })
      .addCase(attemptUpdateEducation.fulfilled, (state, { payload }) => {
        state.education = payload.education
        state.studySchool = payload.studySchool
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.EDUCATION,
        )
      })
      .addCase(attemptUpdateFamilyStatus.fulfilled, (state, { payload }) => {
        state.familyStatus = payload.familyStatus
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.FAMILY_STATUS,
        )
      })
      .addCase(attemptUpdateBirth.fulfilled, (state, { payload }) => {
        state.dateBirth = payload.dateBirth
        state.cityBirth = payload.cityBirth
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.DATE_PLACE_OF_BIRTH,
        )
      })
      .addCase(attemptUpdateProfessions.fulfilled, (state, { payload }) => {
        state.professions = payload.professions
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.PROFESSION,
        )
      })
      .addCase(attemptUpdateSkills.fulfilled, (state, { payload }) => {
        state.skills = payload.skills
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.SKILLS,
        )
      })
      .addCase(attemptUpdateInterests.fulfilled, (state, { payload }) => {
        state.interests = payload.interests
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.INTERESTS,
        )
      })
      .addCase(attemptUpdateCertificates.fulfilled, (state, { payload }) => {
        state.certificates = payload.certificates
        state.stepsToComplete = state.stepsToComplete?.filter(
          (step) => step !== PROFILE_STEP.CERTIFICATES,
        )
      })
      //#region auth actions handler. It should completely overwrite state
      .addCase(attemptLogin.fulfilled, (state, { payload }) => {
        console.log('!!! attemptLogin payload:', payload)
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          createdAt: payload.user.createdAt,
          ...payload.user,
        }
        const user2 = convertUserFromBackToFront(user)
        return user2
      })
      .addCase(attemptGoogle.fulfilled, (state, { payload }) => {
        //TODO: remove profile keep user
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }

        return convertUserFromBackToFront(user)
      })
      .addCase(attemptFacebook.fulfilled, (state, { payload }) => {
        //TODO: remove profile keep user
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }

        return convertUserFromBackToFront(user)
      })
      .addCase(refreshAuthTokens.fulfilled, (state, { payload }) => {
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }

        return convertUserFromBackToFront(user)
      })
      .addCase(registrationConfirmAccount.fulfilled, (state, { payload }) => {
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }

        return convertUserFromBackToFront(user)
      })
      .addCase(registrationSetAddress.fulfilled, (state, { payload }) => {
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }

        return convertUserFromBackToFront(user)
      })
      .addCase(forgotPasswordSendCode.fulfilled, (state, { payload }) => {
        if (payload) {
          const user = {
            reviews: state.reviews,
            rate: state.rate,
            ...payload.user,
          }

          return convertUserFromBackToFront(user)
        }
      })
      .addCase(attemptForgotPasswordClaimCode.fulfilled, (state, { payload }) => {
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }

        return convertUserFromBackToFront(user)
      })
      .addCase(attemptSetPassword.fulfilled, (state, { payload }) => {
        const user = {
          reviews: state.reviews,
          rate: state.rate,
          ...payload.user,
        }

        return convertUserFromBackToFront(user)
      })
      .addCase(attemptUpdateInterestZone.fulfilled, (state, { payload }) => {
        if (payload.polygon) {
          state.interestZone = {
            polygon: {
              type: 'Polygon',
              coordinates: payload.polygon.coordinates.map((p) =>
                p.map((point) => [point[1], point[0]]),
              ),
            },
          }
        } else if (payload.circle) {
          state.interestZone = {
            circle: {
              center: {
                type: 'Point',
                coordinates: [
                  payload.circle.center.coordinates[1],
                  payload.circle.center.coordinates[0],
                ],
              },
              radius: payload.circle.radius,
            },
          }
        }
      })
      .addCase(attemptUpdateSecurity.fulfilled, (state, action) => {
        state.email = action.meta.arg.email
        state.phone = action.meta.arg.phone
        state.address = action.meta.arg.address
        if (state.address && action.meta.arg.address) {
          state.address.coordinates.coordinates = [
            state.address.coordinates.coordinates[1],
            state.address.coordinates.coordinates[0],
          ]
        }
      })
    //#endregion
  },
})

export const { updateAvatar } = userReducer.actions
export default userReducer.reducer
