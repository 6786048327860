import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import $api from '../http'
import { PRIVACY } from '../types/enum'
import { InterestZoneDTO } from '../utils/geo.dto'
import { FeedItemResponseInterface } from './postsSlice'

export interface ServiceExtension {
  category: {
    id: string
    name: string
    image?: string
  }
  subCategory: {
    id: string
    name: string
    image?: string
  }
  phone: string
  interestZone: InterestZoneDTO
}

type CreateServiceDTO = {
  categoryId: string
  subCategoryId: string
  title: string
  text: string
  phone: string
  images: string[]
  privacy: PRIVACY
  address: AddressDTO
  interestZone: InterestZoneDTO
}

export type ServiceDTO = FeedItemResponseInterface & ServiceExtension

interface State {}
export const initialState: State = {}

export const attemptPublishService = createAsyncThunk<void, CreateServiceDTO>(
  'service/create',
  async (payload) => {
    // Invert lat et lng
    if (payload.interestZone.polygon) {
      payload.interestZone.polygon.coordinates[0] =
        payload.interestZone.polygon.coordinates[0].map((v) => [v[1], v[0]])
    }

    const response = await $api.post('/service/published', payload)
    return response.data
  },
)

export const attemptDeleteService = createAsyncThunk<string, string>(
  'service/delete',
  async (id) => {
    const response = await $api.delete(`service/published/${id}`)
    return id
  },
)


export const servicesReducer = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
})

export const {} = servicesReducer.actions
export default servicesReducer.reducer
