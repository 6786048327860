import React, { ReactNode, createContext, useRef } from 'react'
import { Socket } from 'socket.io-client'

const SocketContext = createContext<{
  socket: React.MutableRefObject<Socket | null> | null
}>({
  socket: null,
})

const SocketContextProvider = ({ children }: { children: ReactNode }) => {
  let socketRef = useRef<Socket | null>(null)

  return (
    <SocketContext.Provider
      value={{
        socket: socketRef,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export { SocketContext, SocketContextProvider }
