import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import $api from '../http'
import { EDUCATION, FAMILY_STATUS, ORIENTATION, SEX } from '../types/enum'
import { Interests, Professions, Skills } from '../utils/localization/i18n'

export interface Neighbour {
  _id: string
  email: string
  fullName: string
  avatarFileName?: string
  address: AddressDTO
  aboutMe: string
  dateBirth: string
  cityBirth: string
  sex: SEX
  orientation: ORIENTATION
  education: EDUCATION
  studySchool: string
  familyStatus: FAMILY_STATUS
  nationality: string
  certificates: string[]
  professions: (Professions | string)[]
  interests: (Interests | string)[]
  skills: (Skills | string)[]
}

export interface SearchNeighboursRequest {
  offset?: number
}

type SearchNeighboursResponse = Neighbour[]

interface State {
  neighbours?: Neighbour[]
}

export const initialState: State = {}
//#region methods

export const attemptSearchNeighbours = createAsyncThunk<
  SearchNeighboursResponse,
  SearchNeighboursRequest
>('search/neighbours', async (payload, { getState, dispatch }) => {
  const response = await $api.post(`search/neighbours`, payload)
  return response.data
})

export const searchSlice = createSlice({
  name: 'searchSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attemptSearchNeighbours.fulfilled, (state, action) => {
      if (action.meta.arg.offset && state.neighbours) {
        state.neighbours = [...state.neighbours, ...action.payload]
      } else {
        state.neighbours = action.payload
      }
    })
  },
})

export const {} = searchSlice.actions
export default searchSlice.reducer
