import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PublishPostHttp } from '../http/publish-post-http'
import {
  CommentInterface,
  GetAllPostInterface,
  PinedPostType,
  PostUserInterface,
} from '../types/types'

interface State {
  isLoad: boolean
  publishPosts: PostUserInterface[]
  publishPostsWithMark: PostUserInterface[]
  publishPost: PostUserInterface | null //TODO: unused???
  publishAllPosts: GetAllPostInterface
  publishPinPosts: PinedPostType[]
  publishComments: { comments: CommentInterface[]; countComments: number } //TODO: unused???
}
export const initialState: State = {
  isLoad: false,
  publishPosts: [],
  publishPostsWithMark: [],
  publishPost: null,
  publishAllPosts: { posts: [], allPageNumber: 0 },
  publishPinPosts: [],
  publishComments: { comments: [], countComments: 0 },
}
//#region methods

export const getPosts = createAsyncThunk<
  GetAllPostInterface,
  {
    pageNumber: number
    userId: string
    isMarkedOption?: boolean
    listPinedPost?: string[]
  }
>('posts/get-posts', async (payload) => {
  const response = await PublishPostHttp.getPosts(payload)
  return response
})
export const getTenPosts = createAsyncThunk<PostUserInterface[], void>(
  'posts/get-ten-posts',
  async () => {
    const response = await PublishPostHttp.getTenPosts()
    return response
  },
)
export const getPostsByUserId = createAsyncThunk<PostUserInterface[], { userId: any }>(
  'posts/get-posts-by-id',
  async (payload) => {
    const response = await PublishPostHttp.getPostsByUserId(payload)
    return response
  },
)
export const getPostsByUser = createAsyncThunk<PostUserInterface[], { userId: any }>(
  'posts/get-post-by-userid',
  async (payload) => {
    const response = await PublishPostHttp.getPostsByUser(payload)
    return response
  },
)
export const getPost = createAsyncThunk<
  { post: PostUserInterface },
  { postId: string; userId: string }
>('posts/get-post', async (payload) => {
  const response = await PublishPostHttp.getPost(payload)
  return response
})
export const getComments = createAsyncThunk<
  { comments: CommentInterface[]; countComments: number },
  { postId: string; userId: string }
>('posts/get-comments', async (payload) => {
  const response = await PublishPostHttp.getComments(payload)
  return response
})
export const getMyComments = createAsyncThunk<CommentInterface[], { _id: string }>(
  'posts/get-my-comments',
  async (payload) => {
    const response = await PublishPostHttp.getMyComments(payload)
    return response
  },
)
export const getPostPin = createAsyncThunk<PinedPostType[], { userId: string }>(
  'posts/get-post-pin',
  async (payload) => {
    const response = await PublishPostHttp.getPostPin(payload)
    return response
  },
)
export const getPostsWithMark = createAsyncThunk<
  PostUserInterface[],
  { marckedUserId: string }
>('posts/get-mark', async (payload) => {
  const response = await PublishPostHttp.getPostsWithMark(payload)
  return response
})
//#endregion
//#region slice

export const postsReducer = createSlice({
  name: 'postsReducer',
  initialState: initialState,
  reducers: {
    setLoader: (
      state,
      {
        payload,
      }: {
        payload: boolean
      },
    ) => {
      state.isLoad = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTenPosts.fulfilled, (state, { payload }) => {
        state.publishPosts = payload
      })
      .addCase(getPostsByUserId.fulfilled, (state, { payload }) => {
        state.publishPosts = payload
      })
      .addCase(getPostsByUser.fulfilled, (state, { payload }) => {
        state.publishPosts = payload
      })
      .addCase(getPostsWithMark.fulfilled, (state, { payload }) => {
        state.publishPostsWithMark = payload
      })
      // TODO: unused???
      .addCase(getPost.fulfilled, (state, { payload }) => {
        state.publishPost = payload.post
      })

      .addCase(getPosts.fulfilled, (state, { payload }) => {
        state.publishAllPosts = payload
      })

      .addCase(getPostPin.fulfilled, (state, { payload }) => {
        state.publishPinPosts = payload
      })

      .addCase(getComments.fulfilled, (state, { payload }) => {
        state.publishComments = payload
      })
      .addCase(getMyComments.fulfilled, (state, { payload }) => {
        state.publishComments = { comments: payload, countComments: payload.length }
      })

      .addMatcher(
        (action) => {
          return (
            action.type.endsWith('/pending') ||
            action.type.endsWith('/fulfilled') ||
            action.type.endsWith('/rejected')
          )
        },
        (state, action) => {
          state.isLoad = action.type.endsWith('/pending')
        },
      )
  },
})

export const { setLoader } = postsReducer.actions
export default postsReducer.reducer
