import { ROLE } from '../../types/enum'

export const uploadedBaseURL = process.env.REACT_APP_UPLOADED_BASE_URL || 'undefined'
export const apiBaseURL = process.env.REACT_APP_API_BASE_URL || 'undefined'
export const socketBaseURL = process.env.REACT_APP_WS_BASE_URL || 'undefined'

export const indexPathByRole = (role?: ROLE) => {
  if (role === ROLE.ADMIN || role === ROLE.REGIONAL_ADMIN || role === ROLE.LOCAL_ADMIN) {
    return '/admin'
  }

  if (role === ROLE.USER_EMAIL_UNCONFIRMED) {
    return '/auth/confirm'
  }

  if (role === ROLE.USER_MUST_SET_PASSWORD) {
    return '/reset-password'
  }

  if (role === ROLE.USER_MUST_SET_ADDRESS) {
    return '/location'
  }

  return '/user'
}
