import {
  configureStore,
  combineReducers,
  Reducer,
  AnyAction,
  Middleware,
  createListenerMiddleware,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import activitiesReducer, {
  initialState as activitiesInitialState,
} from './redux/activities'
import feedReducer, { initialState as feedInitialState } from './redux/feedSlice'
import authReducer, { initialState as authInitialState } from './redux/auth'
import appReducer, { initialState as appInitialState } from './redux/app'
import userReducer, { initialState as userInitialState } from './redux/users'
import categoriesReducer, {
  initialState as categoriesInitialState,
} from './redux/categories'
import postsReducer, { initialState as postsInitialState } from './redux/posts'
import postsNewReducer, { initialState as postsNewInitialState } from './redux/postsSlice'
import activityReducer, {
  initialState as activityInitialState,
} from './redux/activitySlice'
import servicesReducer, {
  initialState as servicesInitialState,
} from './redux/serviceSlice'
import searchReducer, { initialState as searchInitialState } from './redux/searchSlice'
import profileReducer, { initialState as profileInitialState } from './redux/profile'
import userNewReducer, { initialState as userNewInitialState } from './redux/userNew'
import chatsReducer, { initialState as chatsInitialState } from './redux/chats'
import messengerReducer, {
  initialState as messengerInitialState,
} from './redux/messenger'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'app', 'user', 'userNew'],
}

//TODO: clean mess with reducers
const combinedReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  messenger: messengerReducer,
  categories: categoriesReducer,
  activities: activitiesReducer,
  posts: postsReducer,
  postsNew: postsNewReducer,
  activity: activityReducer,
  publishedServices: servicesReducer,
  chats: chatsReducer,
  profile: profileReducer,
  userNew: userNewReducer,
  feed: feedReducer,
  search: searchReducer,
})

const initialState = {
  app: appInitialState,
  auth: authInitialState,
  user: userInitialState,
  categories: categoriesInitialState,
  activities: activitiesInitialState,
  activity: activitiesInitialState,
  publishedServices: servicesInitialState,
  posts: postsInitialState,
  postsNew: postsNewInitialState,
  profile: profileInitialState,
  chats: chatsInitialState,
  userNew: userNewInitialState,
  feed: feedInitialState,
  search: searchInitialState,
  messenger: messengerInitialState,
}

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    return combinedReducer({ ...initialState, app: state.app }, action)
  }
  return combinedReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const restoreSettingsMiddleware: Middleware<{}, RootState> =
//   storeApi => next => action => {
//     // TODO: see if middleware is needed, add middleware logic here, ex:
//     // if (action.type === 'persist/REHYDRATE') {
//     //   const blurCamera = localStorage.getItem("settingsBlurCamera") == "true"
//     //   storeApi.dispatch(setBokehEffectActive(blurCamera))
//     // }

//     return next(action)
//   }

const listenerMiddleware = createListenerMiddleware()

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).prepend(listenerMiddleware.middleware),
  // .concat(restoreSettingsMiddleware),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof combinedReducer>
export type AppDispatch = typeof store.dispatch
