import { createSlice } from '@reduxjs/toolkit'
import { LatLng } from 'leaflet'
import {
  attemptLogin,
  registrationConfirmAccount,
  refreshAuthTokens,
  attemptGoogle,
  attemptFacebook,
} from './auth'
import { UserIdentityInterface } from '../redux/profile'
import { ROLE } from '../types/enum'
import { jwtDecode } from 'jwt-decode'
import { Nullable, UserMainInterface } from '../types/types'
import $api from '../http'

export interface UserInitialStateInterface {
  isLoad: boolean
  _id: string
  email: string
  role: ROLE
  fullName: string
  avatarFileName: string | null
  phone?: string
  polygonCoords?: LatLng[] | LatLng[][] | LatLng[][][] | null
  regionAdmin?: {
    fullName: string
    avatarFileName: string | null
  } | null
  coords?: { lat: number; lng: number }
  identity?: UserIdentityInterface
}
export const initialState: UserInitialStateInterface = {
  isLoad: false,
  _id: '',
  email: '',
  role: ROLE.ADMIN,
  fullName: '',
  phone: '',
  avatarFileName: null,
}

export interface UserInitialStateWithDistanceInterface {
  _id: string
  isLoad: boolean
  email: string
  role: ROLE
  fullName: string
  phone?: string
  avatarFileName: string | null
  polygonCoords?: LatLng[] | LatLng[][] | LatLng[][][] | null
  regionAdmin?: {
    fullName: string
    avatarFileName: string | null
  } | null
  coords?: { lat: number; lng: number }
  identity?: UserIdentityInterface
  distance: number
}

export const userTextInfo = async (
  payload: Nullable<UserInitialStateInterface>,
): Promise<Nullable<UserInitialStateInterface>> => {
  try {
    const response = await $api.post('user/user-text-info', payload)
    return response.data
  } catch (error) {
    console.log(error + 'faild')
    throw error
  }
}

export type ChangePasswordType = {
  _id: string
  password: string
  newPassword1: string
  newPassword2: string
}

export const userChangePassword = async (
  payload: ChangePasswordType,
): Promise<string> => {
  try {
    const response = await $api.post('/user/user-change-password', payload)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.log(error + 'Password change faild')
    throw error
  }
}

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setValueUserReducer: (
      state,
      {
        payload,
      }: {
        payload: Nullable<UserInitialStateInterface>
      },
    ) => {
      if (payload?._id) {
        delete payload._id
      }
      Object.assign(state, payload)
    },
    setLoader: (
      state,
      {
        payload,
      }: {
        payload: boolean
      },
    ) => {
      state.isLoad = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptLogin.fulfilled, (state, { payload }) => {
        const payloadDecoded: any = jwtDecode(payload.accessToken)
        state._id = payloadDecoded.id
        state.email = payloadDecoded.email
        state.role = (payloadDecoded.role as string).toUpperCase() as ROLE //TODO why does we handle authorization in user ?
        //FIXME state.fullName = payloadDecoded.......
        // Object.assign(state, jwtDecode(payload.accessToken))
      })
      .addCase(attemptGoogle.fulfilled, (state, { payload }) => {
        const payloadDecoded: any = jwtDecode(payload.accessToken)
        state._id = payloadDecoded.id
        state.email = payloadDecoded.email
        state.role = (payloadDecoded.role as string).toUpperCase() as ROLE //TODO why does we handle authorization in user ?
        //FIXME state.fullName = payloadDecoded.......
        // Object.assign(state, jwtDecode(payload.accessToken))
      })
      .addCase(attemptFacebook.fulfilled, (state, { payload }) => {
        const payloadDecoded: any = jwtDecode(payload.accessToken)
        state._id = payloadDecoded.id
        state.email = payloadDecoded.email
        state.role = (payloadDecoded.role as string).toUpperCase() as ROLE //TODO why does we handle authorization in user ?
        //FIXME state.fullName = payloadDecoded.......
        // Object.assign(state, jwtDecode(payload.accessToken))
      })
      .addMatcher(
        (action) => {
          return (
            action.type.endsWith('/pending') ||
            action.type.endsWith('/fulfilled') ||
            action.type.endsWith('/rejected')
          )
        },
        (state, action) => {
          state.isLoad = action.type.endsWith('/pending')
        },
      )
  },
})

export const { setLoader, setValueUserReducer } = userReducer.actions
export default userReducer.reducer
