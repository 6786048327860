//old

import { createSlice } from '@reduxjs/toolkit'
import { refreshAuthTokens } from './auth'
import { ChatType, RequestType } from '../types/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'

interface State {
  isLoad: boolean
  chats: ChatType[]
  requests: RequestType[]
}

export const initialState: State = {
  isLoad: false,
  chats: [],
  requests: [],
}
//#region methods
export const getUserChats = createAsyncThunk<
  ChatType[],
  { _id: string; isSupport: boolean }
>('messenger/list-chat', async (payload) => {
  const response = await $api.post('messenger/list-chat', payload)
  return response.data
})
export const getUserRequests = createAsyncThunk<RequestType[], { userId: string }>(
  'messenger/get-request',
  async (payload) => {
    const response = await $api.post('messenger/get-request', payload)
    return response.data
  },
)

//#endregion
//#region slice
export const chatsReducer = createSlice({
  name: 'messenger',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserChats.fulfilled, (state, { payload }) => {
        state.chats = payload
      })
      .addCase(getUserRequests.fulfilled, (state, { payload }) => {
        state.requests = payload
      })
  },
})

export default chatsReducer.reducer
