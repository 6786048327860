import { AxiosResponse } from 'axios'
import { LatLng } from 'leaflet'
import $api from '.'
import { UserChat } from '../components/general-components/profile-info/ProfileInfoHelpCenter'
import { UserInitialStateInterface } from '../redux/users'
import { LocationPayload } from '../redux/profile'
import { ROLE } from '../types/enum'
import { FriendTypeResponse, HeaderMessageType } from '../types/types'

interface GetClosestUserInterface {
  role: ROLE
  myLat: number
  myLng: number
}

interface GetUsersInterface {
  _id: string
  role: ROLE
  searchName: string
}

export class UserHttp {
  static async changeLocation(payload: LocationPayload) {
    return await $api.post('identity/profile-location', payload)
  }

  static async getClosestUser(payload: GetClosestUserInterface): Promise<UserChat> {
    const res: AxiosResponse<UserChat> = await $api.post('user/get-closest-user', payload)
    return res.data
  }

  static async getClosestUsers(payload: GetClosestUserInterface): Promise<UserChat> {
    const res: AxiosResponse<UserChat> = await $api.post(
      'user/get-closest-users',
      payload,
    )
    return res.data
  }

  static async getUsersWithCoords(
    payload: GetClosestUserInterface,
  ): Promise<UserInitialStateInterface[]> {
    const res: AxiosResponse<UserInitialStateInterface[]> = await $api.post(
      'user/get-users-with-coords',
      payload,
    )
    return res.data
  }

  static async getUserById(payload: {
    userId: string | any
  }): Promise<HeaderMessageType> {
    const res: AxiosResponse<HeaderMessageType> = await $api.post(
      'user/get-user',
      payload,
    )
    return res.data
  }

  static async getUsers(
    payload: GetUsersInterface,
  ): Promise<UserInitialStateInterface[]> {
    const res: AxiosResponse<UserInitialStateInterface[]> = await $api.post(
      'user/get-users',
      payload,
    )
    return res.data
  }

  static async getAllUsers(): Promise<UserInitialStateInterface[]> {
    const res: AxiosResponse<UserInitialStateInterface[]> =
      await $api.post('user/all-users')
    return res.data
  }

  static async getMyFriends(payload: { _id: string }): Promise<FriendTypeResponse[]> {
    const res: AxiosResponse<FriendTypeResponse[]> = await $api.post(
      'user/get-friends',
      payload,
    )
    return res.data
  }

  static async checkMyFriend(payload: {
    _id: string
    friendId: string
  }): Promise<boolean> {
    const res: AxiosResponse<boolean> = await $api.post('user/check-my-friend', payload)
    return res.data
  }

  static async addMyFriend(payload: {
    _id: string
    friendId: string
  }): Promise<FriendTypeResponse> {
    const res: AxiosResponse<FriendTypeResponse> = await $api.post(
      'user/add-my-friend',
      payload,
    )
    return res.data
  }

  static async deleteMyFriend(payload: {
    _id: string
    friendId: string
  }): Promise<FriendTypeResponse> {
    const res: AxiosResponse<FriendTypeResponse> = await $api.post(
      'user/delete-to-friend',
      payload,
    )
    return res.data
  }

  static async addRegionAdminInfo(payload: {
    userId: string
    polygonCoords: LatLng[] | LatLng[][] | LatLng[][][]
  }): Promise<UserInitialStateInterface> {
    const res: AxiosResponse<UserInitialStateInterface> = await $api.post(
      'user/add-regional',
      payload,
    )
    return res.data
  }

  static async addLocalAdminInfo(payload: {
    userId: string
    polygonCoords: LatLng[] | LatLng[][] | LatLng[][][]
    regionalId: string
  }): Promise<UserInitialStateInterface> {
    const res: AxiosResponse<UserInitialStateInterface> = await $api.post(
      'user/add-local',
      payload,
    )
    return res.data
  }

  // static async addRegionAdminInfo(payload: { userId:string, vassals:UserInitialStateInterface[] }):Promise<UserInitialStateInterface> {
  //     const res: AxiosResponse<UserInitialStateInterface> = await $api.post('user/add-region-info', payload)
  //     return res.data
  // }
}
