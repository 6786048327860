import { ThemeConfig } from 'antd'
import styles from './pallette.module.scss'

export const defaultTheme: ThemeConfig = {
  token: {
    colorPrimary: styles.primaryColor,
    controlHeight: 40,
    fontSize: 16,
    borderRadius: 8,
  },
  components: {
    Button: {
      controlHeight: 50,
      colorText: styles.textButtonColor,
    },
    Select: {
      controlHeight: 50,
    },
    DatePicker: {
      controlHeight: 50,
    },
    Input: {
      controlHeight: 50,
    },
    InputNumber: {
      controlHeight: 50,
    },
    Layout: {
      headerBg: styles.primaryColorL100,
      headerColor: 'white',
    },
  },
}
