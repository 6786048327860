import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useNavigate, Outlet, useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { IconWelcomeLogo } from '../svg/IconWelcomeLogo'

export default function AppFallback() {
  return (
    <Layout
      style={{
        position: 'absolute',
        inset: 0,
        backgroundImage: `url(/Images/welcomebg.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <IconWelcomeLogo />
    </Layout>
  )
}
