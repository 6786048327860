import { AxiosResponse } from 'axios'
import $api from '.'
import { hiddenService } from '../components/user-components/services/UserServicesMain'
import { ServiceCategory, ServiceSubCategory } from '../redux/categories'
import {
  Activities,
  GetAllPublishServicetInterface,
  HideServiceType,
  PublishServiceItemInterface,
  PublishServiceOneItemInterface,
  Review,
  ServiceInterfaceMarked,
} from '../types/types'

export class ServiceHttp {
  static async getAllService(): Promise<ServiceCategory[]> {
    const res: AxiosResponse<ServiceCategory[]> = await $api.get(
      'categories/all-categories',
    )
    return res.data
  }

  static async getAllServiceByName({
    title,
  }: {
    title: string
  }): Promise<PublishServiceItemInterface[]> {
    const res: AxiosResponse<PublishServiceItemInterface[]> = await $api.get(
      `categories/all-services?name=${title}`,
    )
    console.log('title:' + title)
    return res.data
  }

  static async getAllSubService({ id }: { id: string }): Promise<ServiceSubCategory[]> {
    const res: AxiosResponse<ServiceSubCategory[]> = await $api.get(
      `categories/sub-categories?id=${id}`,
    )
    return res.data
  }

  static async getUserPublishService(payload: {
    userId: any
  }): Promise<PublishServiceItemInterface[]> {
    const res: AxiosResponse<PublishServiceItemInterface[]> = await $api.post(
      `categories/get-user-service`,
      payload,
    )
    return res.data
  }

  static async getHiddenService(payload: { userId: any }): Promise<hiddenService[]> {
    const res: AxiosResponse<hiddenService[]> = await $api.post(
      `categories/get-hidden-services`,
      payload,
    )
    return res.data
  }

  static async addPublishService(payload: FormData) {
    const res: AxiosResponse<Activities> = await $api.post(
      'categories/add-publish-service',
      payload,
    )
    return res.data
  }

  static async addPublishServiceReview(payload: {
    serviceId: string
    userId: string
    ownerId: string
    rate: number
    text: string
    createdPublishServiceDate: Date
  }) {
    const res: AxiosResponse<Review> = await $api.post('categories/add-review', payload)
    return res.data
  }

  static async deletePublishService(payload: { _id: string }) {
    const res: AxiosResponse<PublishServiceItemInterface> = await $api.post(
      'categories/delete-publish-service',
      payload,
    )
    return res.data
  }

  static async addMark(payload: { serviceId: string; marckedUserId: string }) {
    const res: AxiosResponse<PublishServiceItemInterface> = await $api.post(
      'categories/add-publish-mark',
      payload,
    )
    return res.data
  }
  static async deleteMark(payload: { serviceId: string; marckedUserId: string }) {
    const res: AxiosResponse<PublishServiceItemInterface> = await $api.post(
      'categories/delete-publish-mark',
      payload,
    )
    return res.data
  }

  static async getServicesWithMark(payload: {
    userId: string
  }): Promise<ServiceInterfaceMarked[]> {
    const res: AxiosResponse<ServiceInterfaceMarked[]> = await $api.post(
      'categories/get-publish-mark',
      payload,
    )
    return res.data
  }

  static async hideService(payload: {
    userId: string
    serviceId: string
  }): Promise<HideServiceType[]> {
    const res: AxiosResponse<HideServiceType[]> = await $api.post(
      'categories/hide-service',
      payload,
    )
    return res.data
  }

  static async changeServicePrivacy(payload: {
    serviceId: string
    privacyService: string
  }) {
    const res: AxiosResponse<PublishServiceItemInterface> = await $api.post(
      'categories/update-publish-privacy',
      payload,
    )
    return res.data
  }

  static async updateNotification(payload: { serviceId: string; userId: string }) {
    const res: AxiosResponse<PublishServiceItemInterface> = await $api.post(
      'categories/update-publish-notification',
      payload,
    )
    return res.data
  }

  static async getPublishServiceReview(payload: { serviceId: any }) {
    const res: AxiosResponse<Review[]> = await $api.post('categories/get-review', payload)
    return res.data
  }

  static async getUserPublishServiceReview(payload: { ownerId: any }) {
    const res: AxiosResponse<Review[]> = await $api.post(
      'categories/get-user-reviews',
      payload,
    )
    return res.data
  }

  static async getAllPublishService(payload: {
    pageNumber: number
    subServicesId: string
  }): Promise<GetAllPublishServicetInterface> {
    const res: AxiosResponse<GetAllPublishServicetInterface> = await $api.post(
      `categories/get-publish-service`,
      payload,
    )
    return res.data
  }

  static async moveSubService(payload: { newCategoryId: string; subCategiryId: string }) {
    const res: AxiosResponse<Activities> = await $api.post(
      'categories/move-subcategory',
      payload,
    )
    return res.data
  }

  static async getOnePublishService(): Promise<PublishServiceItemInterface[]> {
    const res: AxiosResponse<PublishServiceItemInterface[]> = await $api.post(
      `categories/get-ten-publish-service`,
    )
    return res.data
  }

  static async getTenPublishService(): Promise<PublishServiceItemInterface[]> {
    const res: AxiosResponse<PublishServiceItemInterface[]> = await $api.post(
      `categories/get-ten-publish-service`,
    )
    return res.data
  }

  static async getOnePublsihService(body: {
    publishServiceId: string
    userId: string
  }): Promise<PublishServiceOneItemInterface> {
    const res: AxiosResponse<PublishServiceOneItemInterface> = await $api.post(
      `categories/get-one-publish-service`,
      body,
    )
    return res.data
  }

  static async getOnePublsihServiceMedia(body: {
    publishServiceId: string
  }): Promise<PublishServiceOneItemInterface> {
    const res: AxiosResponse<PublishServiceOneItemInterface> = await $api.post(
      `categories/get-one-publish-service-media`,
      body,
    )
    return res.data
  }
}
