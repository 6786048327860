import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/uk'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import authEN from './locales/auth.en.json'
import profileEN from './locales/profile.en.json'
import publicationsEN from './locales/publications.en.json'
import professionListEN from './locales/datasets/professions.en.json' //https://github.com/BogdanGorelkin/professions
import skillListEN from './locales/datasets/skills.en.json'
import interestsListEN from './locales/datasets/interests.en.json'
import frFR from 'antd/locale/fr_FR'
import enGB from 'antd/locale/en_GB'
import ukUA from 'antd/locale/uk_UA'
import { Locale } from 'antd/lib/locale'
import nationalitiesEN from 'i18n-nationality/langs/en.json'
import nationalities from 'i18n-nationality'

const nationalitiesResources: {
  [key: string]: { locale: string; nationalities: { [key: string]: string } }
} = {
  en: nationalitiesEN,
}
export type Professions = keyof typeof professionListEN
export type Skills = keyof typeof skillListEN
export type Interests = keyof typeof interestsListEN
export type LocaleType = 'fr' | 'de' | 'it' | 'uk' | 'en'

/**
 * Get the locale ant object from the locale string
 * @param locale i18n locale string
 * @returns Locale ant object
 */
export const antLocale: { [key: string]: Locale } = {
  fr: frFR,
  uk: ukUA,
  en: enGB,
}

export const resources = {
  en: {
    auth: authEN,
    profile: profileEN,
    professionsList: professionListEN,
    skillsList: skillListEN,
    interestsList: interestsListEN,
    publications: publicationsEN,
  },
} // as const

i18n.use(initReactI18next).init(
  {
    lng: localStorage.getItem('settingsLng') || process.env.REACT_APP_DEFAULT_LOCALE,
    fallbackLng: 'en',
    resources,
    nsSeparator: false,
  },
  () => {
    // const nationalityResource = nationalitiesResources[i18n.language] || nationalitiesEN
    nationalities.registerLocale(nationalitiesResources['en'])
  },
)

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('settingsLng', lng)
})

export function getNationalityOptions() {
  //TODO: add internationalization. getName will not work if
  // nationalities.registerLocale('f.e.: german resource')
  //but getName(code, 'en')

  const nationalityCodes = nationalities.getAlpha2Codes()
  const options = Object.keys(nationalityCodes).map((code) => ({
    label: nationalities.getName(nationalityCodes[code], 'en'),
    value: code,
  })) //TODO: get name accordingly to the current app language
  return options
}

export default i18n
