import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import $api from '../http'
import { PRIVACY } from '../types/enum'
import { UserInterface } from './userNew'

export interface FeedItemInterface {
  title: string
  text: string
  images: string[]
  privacy: PRIVACY
  address: AddressDTO
}

export type Author = Pick<UserInterface, 'email' | 'avatarFileName' | 'fullName'> & {
  _id: string
}

export interface FeedItemResponseInterface extends FeedItemInterface {
  statistic: {
    clickedBy: string[]
    commentsCount: number
    shareCount: number
    viewedBy: string[]
  }
  createdAt: string
  deleted: boolean
  author: Author
  _id: string
}

type CreatePostDTO = FeedItemInterface
export type PostDTO = FeedItemResponseInterface

interface State {}
export const initialState: State = {}
//#region methods

export const attemptPublishPost = createAsyncThunk<PostDTO, CreatePostDTO>(
  'posts/publish',
  async (payload) => {
    const response = await $api.post(`post`, payload)
    return response.data
  },
)

export const attemptDeletePost = createAsyncThunk<string, string>(
  'post/delete',
  async (id) => {
    const response = await $api.delete(`post/${id}`)
    return id
  },
)

//#endregion
//#region slice

export const postsReducer = createSlice({
  name: 'postsReducer',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {},
})

export const {} = postsReducer.actions
export default postsReducer.reducer
