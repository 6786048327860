import { UserInterface } from '../../redux/userNew'

export enum PROFILE_STEP {
  AVATAR = 'AVATAR',
  INTEREST_ZONE = 'INTEREST_ZONE',
  PRIVACY = 'PRIVACY',
  ABOUT_ME = 'ABOUT_ME',
  PROFESSION = 'PROFESSION',
  SKILLS = 'SKILLS',
  INTERESTS = 'INTERESTS',
  CERTIFICATES = 'CERTIFICATES',
  DATE_PLACE_OF_BIRTH = 'DATE_PLACE_OF_BIRTH',
  NATIONALITY = 'NATIONALITY',
  SEX = 'SEX',
  EDUCATION = 'EDUCATION',
  FAMILY_STATUS = 'FAMILY_STATUS',
}

export type EditUserProfileDTO = Pick<
  UserInterface,
  | 'fullName'
  | 'aboutMe'
  | 'dateBirth'
  | 'cityBirth'
  | 'studySchool'
  | 'education'
  | 'sex'
  | 'orientation'
  | 'familyStatus'
  | 'certificates'
  | 'nationality'
>

export interface UpdateAvatarDTO {}
export interface UpdateAvatarResponseDTO extends Pick<UserInterface, 'stepsToComplete'> {
  filename: UserInterface['avatarFileName']
}
