import { AxiosResponse } from 'axios'
import $api from '.'
import {
  Activities,
  CommentInterface,
  GetAllPublishActivitiesInterface,
  HideEventType,
  InviteType,
  PublishActivitiesOneItemInterface,
  PublishEventItemInterface,
} from '../types/types'

export class ActivitiesHttp {
  static async getAllActivities(): Promise<Activities[]> {
    const res: AxiosResponse<Activities[]> = await $api.get('activities/all-activities')
    return res.data
  }

  static async getUserPublishEvent(payload: {
    userId: any
  }): Promise<PublishEventItemInterface[]> {
    const res: AxiosResponse<PublishEventItemInterface[]> = await $api.post(
      `activities/get-user-activities`,
      payload,
    )
    return res.data
  }

  static async addPublishActivitie(payload: FormData) {
    const res: AxiosResponse<Activities> = await $api.post(
      'activities/add-publish-activities',
      payload,
    )
    return res.data
  }

  // new start

  static async addMark(payload: { eventId: string; marckedUserId: string }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/add-mark',
      payload,
    )
    return res.data
  }

  static async addInvite(payload: { eventId: string; userId: string; ownerId: string }) {
    const res: AxiosResponse<InviteType> = await $api.post('activities/invite', payload)
    return res.data
  }
  static async getInvites(payload: { userId: string }) {
    const res: AxiosResponse<InviteType[]> = await $api.post(
      'activities/get-invites',
      payload,
    )
    return res.data
  }

  static async deleteMark(payload: { eventId: string; marckedUserId: string }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/delete-mark',
      payload,
    )
    return res.data
  }

  static async changeEventPrivacy(payload: { eventId: string; privacyEvent: string }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/update-publish-privacy',
      payload,
    )
    return res.data
  }

  static async updateNotification(payload: { eventId: string; userId: string }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/update-publish-notification',
      payload,
    )
    return res.data
  }

  static async hideService(payload: { userId?: string; eventId?: string }) {
    const res: AxiosResponse<HideEventType[]> = await $api.post(
      'activities/hide-event',
      payload,
    )
    return res.data
  }

  static async deletePublishEvent(payload: { _id: string }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/delete-publish-event',
      payload,
    )
    return res.data
  }

  static async addComment(payload: {
    eventId: string
    userId: string
    userIdentityId: string
    text: string
    createdDateComment: Date
  }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/add-comment',
      payload,
    )
    return res.data
  }

  static async getComments(payload: { eventId: string; userId: string }) {
    const res: AxiosResponse<{ comments: CommentInterface[]; countComments: number }> =
      await $api.post('activities/get-comments', payload)
    return res.data
  }

  static async replyOnComment(payload: {
    commentId: string
    replyText: string
    userId: string
    eventId: string
    createdDateComment: Date
  }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/reply-comment',
      payload,
    )
    return res.data
  }

  static async getEventsWithMark(payload: {
    userId: string
  }): Promise<PublishEventItemInterface[]> {
    const res: AxiosResponse<PublishEventItemInterface[]> = await $api.post(
      'activities/get-publish-mark',
      payload,
    )
    return res.data
  }

  // new end

  static async share(payload: { eventId: string }) {
    const res: AxiosResponse<PublishEventItemInterface> = await $api.post(
      'activities/share',
      payload,
    )
    return res.data
  }

  static async getAllPublishEvent(payload: {
    pageNumber: number
    activitiesId: string
    userId: any
  }): Promise<GetAllPublishActivitiesInterface> {
    const res: AxiosResponse<GetAllPublishActivitiesInterface> = await $api.post(
      `activities/get-publish-activities`,
      payload,
    )
    return res.data
  }

  static async getTenPublishActivities(payload: {
    userId: any
  }): Promise<PublishEventItemInterface[]> {
    const res: AxiosResponse<PublishEventItemInterface[]> = await $api.post(
      `activities/get-ten-publish-activities`,
      payload,
    )
    return res.data
  }

  static async getOnePublsihActivities(body: {
    publishActivitiesId: string
    userId: string
  }): Promise<PublishActivitiesOneItemInterface> {
    const res: AxiosResponse<PublishActivitiesOneItemInterface> = await $api.post(
      `activities/get-one-publish-activities`,
      body,
    )
    return res.data
  }

  static async getOnePublsihActivity(body: {
    publishActivitiesId: string
  }): Promise<PublishActivitiesOneItemInterface> {
    const res: AxiosResponse<PublishActivitiesOneItemInterface> = await $api.post(
      `activities/get-one-publish-activity`,
      body,
    )
    return res.data
  }
}
