import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddressDTO } from '../components/general-components/location/AddressDTO'
import { Message } from '../components/Messenger/useChat'
import { Neighbour } from './searchSlice'

export enum CHAT_TYPE {
  P2P = 'P2P',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface OpenRoom {
  _id: string
  type: CHAT_TYPE.OPEN
  participants: Neighbour[]
  createdAt: string
  createdBy: Neighbour
  name: string
  address: AddressDTO
  image: string
}

export interface Room {
  _id: string
  type: CHAT_TYPE
  participants: Neighbour[]
  lastMessage?: Message
  createdAt: string
  createdBy?: Neighbour
  name?: string
  address?: AddressDTO
  image?: string
}

type RoomId = string
interface State {
  allRooms: Room[]
  messages: Record<RoomId, Message[]>
}

export const initialState: State = {
  allRooms: [],
  messages: {},
}
//#region methods

//#endregion

//#region slice
export const messengerReducer = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    setAllRooms: (state, { payload: allRooms }: PayloadAction<Room[]>) => {
      state.allRooms = allRooms
    },
    addToAllRooms: (state, { payload: room }: PayloadAction<Room>) => {
      state.allRooms ? state.allRooms.push(room) : (state.allRooms = [room])
    },
    // setMessages: (state, { payload }: PayloadAction<{ roomId: RoomId, messages: Message[] }>) => {
    //   const { roomId, messages } = payload
    //   state.messages[roomId] = messages
    // },

    addMessages: (
      state,
      {
        payload,
      }: PayloadAction<{ roomId: RoomId; messages: Message[]; isNew?: boolean }>,
    ) => {
      const { roomId, messages, isNew } = payload
      if (!state.messages[roomId]) {
        state.messages[roomId] = []
      }
      state.messages[roomId] = isNew
        ? messages.concat(state.messages[roomId])
        : state.messages[roomId].concat(messages)
    },
  },
  extraReducers: (builder) => {},
})

export const { setAllRooms, addToAllRooms, /* setMessages, */ addMessages } =
  messengerReducer.actions
export default messengerReducer.reducer
