import { createSlice } from '@reduxjs/toolkit'
import { hiddenService } from '../components/user-components/services/UserServicesMain'
import {
  GetAllPublishServicetInterface,
  PublishServiceItemInterface,
  PublishServiceOneItemInterface,
  Review,
  ServiceInterfaceMarked,
} from '../types/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { ServiceHttp } from '../http/service-http'

export interface ServiceCategory {
  _id: string
  name: string
  image: string
  numberView: number
  isVisible: boolean
}

export interface ServiceSubCategory extends ServiceCategory {
  categoryId: string
}

interface State {
  categories?: ServiceCategory[]
  subCategories?: ServiceSubCategory[]

  // TODO: All these are not used
  isLoad: boolean
  markedServices: ServiceInterfaceMarked[]
  publishServices: PublishServiceItemInterface[]
  reviews: Review[]
  publishService: PublishServiceOneItemInterface | null
  hiddenService: hiddenService[]
}

export const initialState: State = {
  // TODO: All these are not used
  isLoad: false,
  markedServices: [],
  publishServices: [],
  reviews: [],
  publishService: null,
  hiddenService: [],
}

//<respons axios, payload >
/////////////////////////////////////get all
export const fetchServiceCategories = createAsyncThunk<ServiceCategory[], void>(
  'categories/fetch-categories',
  async () => {
    const response = await $api.get('/service/category/all')
    return response.data
  },
)

export const fetchServiceSubcategories = createAsyncThunk<ServiceSubCategory[], void>(
  `categories/sub-categories`,
  async () => {
    const response = await $api.get('/service/sub-category/all')
    return response.data
  },
)

//////////////////////////// delete

export const deleteCategories = createAsyncThunk<string, { id: string }>(
  `categories/delete-category`,
  async (payload) => {
    const response = await $api.post(`categories/delete-category`, {
      categiryId: payload.id,
    })
    return response.data
  },
)

export const deleteSubCategories = createAsyncThunk<string, { id: string }>(
  `categories/delete-subcategory`,
  async (payload) => {
    const response = await $api.post(`categories/delete-subcategory`, {
      subCategiryId: payload.id,
    })
    return response.data
  },
)

///////////////////////// visiable

export const visiableCategories = createAsyncThunk<
  { id: string; isVisiable: boolean },
  { id: string; isVisiable: boolean }
>(`category/:id/update/visible`, async (payload) => {
  const response = await $api.post(`category/${payload.id}/update/visible`, {
    isVisible: payload.isVisiable,
  })
  return response.data
})

export const visiableSubCategories = createAsyncThunk<
  { id: string; isVisiable: boolean },
  { id: string; isVisiable: boolean }
>(`categories/visiable-subcategory`, async (payload) => {
  const response = await $api.post(`categories/visiable-subcategory`, payload)
  return response.data
})

export const getUserPublishService = createAsyncThunk<
  PublishServiceItemInterface[],
  { userId: any }
>(`categories/get-user-service`, async (payload) => {
  const response = await ServiceHttp.getUserPublishService(payload)
  return response
})

export const getHiddenService = createAsyncThunk<hiddenService[], { userId: any }>(
  `categories/get-hidden-services`,
  async (payload) => {
    const response = await ServiceHttp.getHiddenService(payload)
    return response
  },
)

export const getServicesWithMark = createAsyncThunk<
  ServiceInterfaceMarked[],
  { userId: any }
>('categories/get-publish-mark', async (payload) => {
  const response = await ServiceHttp.getServicesWithMark(payload)
  return response
})

export const getPublishServiceReview = createAsyncThunk<Review[], { serviceId: any }>(
  'categories/get-review',
  async (payload) => {
    const response = await ServiceHttp.getPublishServiceReview(payload)
    return response
  },
)

export const getUserPublishServiceReview = createAsyncThunk<Review[], { ownerId: any }>(
  'categories/get-user-reviews',
  async (payload) => {
    const response = await ServiceHttp.getUserPublishServiceReview(payload)
    return response
  },
)

export const getAllPublishService = createAsyncThunk<
  GetAllPublishServicetInterface,
  { pageNumber: number; subServicesId: string }
>(`categories/get-publish-service`, async (payload) => {
  const response = await ServiceHttp.getAllPublishService(payload)
  return response
})

export const getTenPublishService = createAsyncThunk<PublishServiceItemInterface[], void>(
  `categories/get-ten-publish-service`,
  async () => {
    const response = await ServiceHttp.getTenPublishService()
    return response
  },
)

export const getOnePublsihService = createAsyncThunk<
  PublishServiceOneItemInterface,
  { publishServiceId: string; userId: string }
>(`categories/get-one-publish-service`, async (payload) => {
  const response = await ServiceHttp.getOnePublsihService(payload)
  return response
})

export const categoriesReducer = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategorie: (
      state,
      {
        payload,
      }: {
        payload: ServiceCategory
      },
    ) => {},
  },
  extraReducers: (builder) => {
    builder
      /////////////////////////////////GET all categories subcategories
      .addCase(fetchServiceCategories.fulfilled, (state, { payload }) => {
        state.categories = payload
      })
      .addCase(fetchServiceSubcategories.fulfilled, (state, { payload }) => {
        state.subCategories = payload
      })
  },
})

export const { addCategorie } = categoriesReducer.actions
export default categoriesReducer.reducer
