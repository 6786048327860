import { lazy, Suspense } from 'react'
import AppFallback from './components/user-components/AppFallback'
import { SocketContextProvider } from './context/SocketContext'
import useSessionExpiration from './utils/useSessionExpiration'

const AppRoutes = lazy(() => import('./AppRoutes'))
export default function App() {
  useSessionExpiration()
  return (
    <SocketContextProvider>
      <Suspense fallback={<AppFallback />}>
        <AppRoutes />
      </Suspense>
    </SocketContextProvider>
  )
}
