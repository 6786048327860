import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import $api from '../http'
import { ActivitiesHttp } from '../http/activities-http'
import {
  Activities,
  CommentInterface,
  GetAllPublishActivitiesInterface,
  InviteType,
  PublishActivitiesOneItemInterface,
  PublishEventItemInterface,
} from '../types/types'

interface State {
  isLoad: boolean
  activities: Activities[]
  publishActivities: PublishEventItemInterface[]
  comments: { comments: CommentInterface[]; countComments: number }
  invites: InviteType[]
}

export const initialState: State = {
  isLoad: false,
  activities: [],
  publishActivities: [],
  comments: { comments: [], countComments: 0 },
  invites: [],
}

//#region methods
export const allActivities = createAsyncThunk<Activities[], void>(
  'activities/all-activities',
  async () => {
    const response = await ActivitiesHttp.getAllActivities()
    return response
  },
)

export const UserPublishEvent = createAsyncThunk<
  PublishEventItemInterface[],
  { userId: any }
>('activities/get-user-activities', async (payload) => {
  const response = await ActivitiesHttp.getUserPublishEvent({ userId: payload.userId })
  return response
})

// export const addPublishActivitie = createAsyncThunk<Activities, FormData>(
//     "activities/add-publish-activities",
//     async (payload) => {
//         const response = await ActivitiesHttp.addPublishActivitie(payload)
//         return response
//     }
// )

// export const addMark = createAsyncThunk<PublishEventItemInterface, {eventId:string, marckedUserId:string}>(
//     "activities/add-mark",
//     async (payload) => {
//         const response = await ActivitiesHttp.addMark(payload)
//         return response
//     }
// )

// export const addInvite = createAsyncThunk<InviteType, {eventId:string, userId:string, ownerId:string}>(
//     "activities/invite",
//     async (payload) => {
//         const response = await ActivitiesHttp.addInvite(payload)
//         return response
//     }
// )

export const getInvites = createAsyncThunk<InviteType[], { userId: string }>(
  'activities/get-invites',
  async (payload) => {
    const response = await ActivitiesHttp.getInvites(payload)
    return response
  },
)

// export const deleteMark = createAsyncThunk<PublishEventItemInterface, {eventId:string, marckedUserId:string}>(
//     "activities/delete-mark",
//     async (payload) => {
//         const response = await ActivitiesHttp.deleteMark(payload)
//         return response
//     }
// )
// export const changeEventPrivacy = createAsyncThunk<PublishEventItemInterface, {eventId:string, privacyEvent:string}>(
//     "activities/update-publish-privacy",
//     async (payload) => {
//         const response = await ActivitiesHttp.changeEventPrivacy(payload)
//         return response
//     }
// )

// export const updateNotification = createAsyncThunk<PublishEventItemInterface, {eventId: string,userId: string}>(
//     "activities/update-publish-notification",
//     async (payload) => {
//         const response = await ActivitiesHttp.updateNotification(payload)
//         return response
//     }
// )

// export const hideService = createAsyncThunk<HideEventType[], {userId?: string, eventId?: string }>(
//     "activities/hide-event",
//     async (payload) => {
//         const response = await ActivitiesHttp.hideService(payload)
//         return response
//     }
// )

// export const deletePublishEvent = createAsyncThunk<PublishEventItemInterface, {_id:string}>(
//     "activities/delete-publish-event",
//     async (payload) => {
//         const response = await ActivitiesHttp.deletePublishEvent(payload)
//         return response
//     }
// )

// export const addComment = createAsyncThunk<PublishEventItemInterface, { eventId: string, userId: string, userIdentityId: string, text: string}>(
//     "activities/add-comment",
//     async (payload) => {
//         const response = await ActivitiesHttp.addComment(payload)
//         return response
//     }
// )

export const getComments = createAsyncThunk<
  { comments: CommentInterface[]; countComments: number },
  { eventId: string; userId: string }
>('activities/get-comments', async (payload) => {
  const response = await ActivitiesHttp.getComments(payload)
  return response
})

// export const replyOnComment = createAsyncThunk<PublishEventItemInterface, {commentId:string, replyText:string, userId:string, eventId: string}>(
//     "activities/reply-comment",
//     async (payload) => {
//         const response = await ActivitiesHttp.replyOnComment(payload)
//         return response
//     }
// )

export const getEventsWithMark = createAsyncThunk<
  PublishEventItemInterface[],
  { userId: string }
>('activities/get-publish-mark', async (payload) => {
  const response = await ActivitiesHttp.getEventsWithMark(payload)
  return response
})

// export const share = createAsyncThunk<PublishEventItemInterface, { eventId: string }>(
//     "activities/share",
//     async (payload) => {
//         const response = await ActivitiesHttp.share(payload)
//         return response
//     }
// )

export const getAllPublishEvent = createAsyncThunk<
  GetAllPublishActivitiesInterface,
  { pageNumber: number; activitiesId: string; userId: any }
>(`activities/get-publish-activities`, async (payload) => {
  const response = await ActivitiesHttp.getAllPublishEvent(payload)
  return response
})

export const getTenPublishActivities = createAsyncThunk<
  PublishEventItemInterface[],
  { userId: any }
>(`activities/get-ten-publish-activities`, async (payload) => {
  const response = await ActivitiesHttp.getTenPublishActivities(payload)
  return response
})

export const getOnePublsihActivities = createAsyncThunk<
  PublishActivitiesOneItemInterface,
  { publishActivitiesId: string; userId: string }
>(`activities/get-one-publish-activities`, async (payload) => {
  const response = await ActivitiesHttp.getOnePublsihActivities(payload)
  return response
})

export const addActivities = createAsyncThunk<Activities, FormData>(
  'activities/add-activity',
  async (payload) => {
    const response = await $api.post('activities/add-activity', payload)
    return response.data
  },
)

export const deleteActivities = createAsyncThunk<string, { id: string }>(
  `activities/delete-activities`,
  async (payload) => {
    const response = await $api.post(`activities/delete-activities`, { id: payload.id })
    return response.data
  },
)

export const visiableActivities = createAsyncThunk<
  { id: string; isVisiable: boolean },
  { id: string; isVisiable: boolean }
>(`activities/visiable-activities`, async (payload) => {
  const response = await $api.post(`activities/visiable-activities`, payload)
  return response.data
})

//#endregion
//#region slice
export const activitiesReducer = createSlice({
  name: 'activities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(allActivities.fulfilled, (state, { payload }) => {
        state.activities = payload
      })
      .addCase(addActivities.fulfilled, (state, { payload }) => {
        state.activities = [...state.activities, payload]
      })
      .addCase(deleteActivities.fulfilled, (state, { payload }) => {
        state.activities = state.activities.filter((item) => item._id !== payload)
      })

      .addCase(getEventsWithMark.fulfilled, (state, { payload }) => {
        state.publishActivities = payload
      })

      .addCase(getTenPublishActivities.fulfilled, (state, { payload }) => {
        state.publishActivities = payload
      })
      .addCase(getOnePublsihActivities.fulfilled, (state, { payload }) => {
        state.publishActivities = [payload]
      })
      .addCase(UserPublishEvent.fulfilled, (state, { payload }) => {
        state.publishActivities = payload
      })
      //TODO: unused???
      .addCase(getComments.fulfilled, (state, { payload }) => {
        state.comments = payload
      })

      //TODO: unused???
      .addCase(getInvites.fulfilled, (state, { payload }) => {
        state.invites = payload
      })

      .addMatcher(
        (action) => {
          return (
            action.type.endsWith('/pending') ||
            action.type.endsWith('/fulfilled') ||
            action.type.endsWith('/rejected')
          )
        },
        (state, action) => {
          state.isLoad = action.type.endsWith('/pending')
        },
      )
  },
})

export default activitiesReducer.reducer
